.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}

.w-control {
  width: 80% !important;
  padding: 5px 10px !important;
}

.w-control-container {
  div {
    justify-content: center;
  }
}

.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.table-avatar-lg {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;
  border: 2px solid $primary;
  img {
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    font-size: 22px;
    font-weight: 500;
    color: white;
  }
}

.table-avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;
  border: 2px solid $primary;
  img {
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    font-size: 22px;
    font-weight: 500;
    color: white;
  }
}

.search-box {
  .form-control {
    border-radius: 30px;
    padding-left: 40px;
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
  }
}

.react-tagsinput-tag {
  background-color: #959595 !important;
  border-color: transparent !important;
  border-radius: 4px !important;
  color: white !important;
}

.react-tagsinput-remove {
  color: white !important;
  padding-left: 10px;
}

.auth-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("/assets/images/bg.png");
}

.cursor-pointer {
  cursor: pointer;
}

.large-circle {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.draggable-content {
  height: 100px;
  width: 100px;
  display: inline-block;

  img {
    height: 100px !important;
    object-fit: cover;
  }
}

.table-image-content {
  height: 60px;
  width: 60px;
  display: inline-block;

  img {
    height: 60px !important;
    object-fit: cover;
  }
}

.draggable-wrapper {
  overflow: auto;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}
