.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  flex: 1;

  &::before {
    position: absolute;
    content: "";
    border-bottom: 3px solid #ccc;
    width: 100%;
    top: 15px;
    left: -50%;
    z-index: 2;
  }

  &::after {
    position: absolute;
    content: "";
    border-bottom: 3px solid #ccc;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 2;
  }

  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }

  &.completed {
    .step-counter {
      background-color: #8DC63F;
      color: white;
    }

    &::after {
      position: absolute;
      content: "";
      border-bottom: 3px solid #8DC63F;
      width: 100%;
      top: 15px;
      left: 50%;
      z-index: 3;
    }
  }

  &.active {
    .step-counter {
      background-color: #8DC63F;
      color: white;
    }
  }

  &:first-child::before, &:last-child::after {
    content: none;
  }
}

@media (max-width: 768px) {
  .stepper-item {
    font-size: 12px;
  }
}
